// /** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import React from 'react'
import { Fragment } from 'react'
import Layout from '../@lekoarts/gatsby-theme-cara/components/layout'
import { Filter } from '../@lekoarts/gatsby-theme-cara/components/ProjectList/Filter'
import WorkCard from '../@lekoarts/gatsby-theme-cara/components/ProjectList/WorkCard'
import Head from '../@lekoarts/gatsby-theme-cara/components/Home/Head'
import { FooterList } from '../@lekoarts/gatsby-theme-cara/components/FooterList'
import Footer from '../@lekoarts/gatsby-theme-cara/components/footer'

const Cara = () => {
  return (
    <Layout>
      <Head />
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100vh',
          overflow: 'hidden scroll',
          transform: 'translate3d(0px, 0px, 0px)',
          fontFamily: 'Noto Sans JP, sans-serif',
        }}
      >
        <Box
          sx={{
            overflow: 'hidden',
            position: 'absolute',
            width: '100%',
            transform: 'translate3d(0px, 0px, 0px)',
          }}
        >
          <Fragment>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: ['column', 'column', 'row'],
                alignItems: ['center', 'center', 'flex-start'],
                backgroundColor: 'rgba(108, 155, 208, 0.14)',
                width: '100vw',
              }}
            >
              <Filter />
              <WorkCard />
            </Box>
            <FooterList />
            <Footer />
          </Fragment>
        </Box>
      </Box>
    </Layout>
  )
}

export default Cara
