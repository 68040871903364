/** @jsx jsx */
import { jsx, Box } from 'theme-ui'

const WorkCard = () => {
  const cardData = [
    {
      title:
        '【React/経験5年】建材業DXサービス開発支援/フロントエンドエンジニアの案件・求人',
      description: 'フロントエンドエンジニアを募集中！',
      image: 'interview-icon.svg',
      interview: '1回',
      remote: 'true',
      salary: '900,000',
    },
    {
      title:
        '【JavaScript/経験5年】WEB制作サービス支援/フロントエンドエンジニアの案件・求人',
      description: 'デザインをリードするデザイナーを募集中！',
      image: 'interview-icon.svg',
      interview: '1回',
      remote: 'true',
      salary: '1,000,000',
    },
    {
      title:
        '【React/経験5年】製造業DXサービス開発支援におけるエンジニアの案件・求人',
      description: 'フロントエンドエンジニアを募集中！',
      image: 'interview-icon.svg',
      interview: '1回',
      remote: 'true',
      salary: '800,000',
    },
    {
      title:
        '【TypeScript/React.js】ライブ配信サービスにおけるエンジニアの案件・求人',
      description: 'プロジェクトマネージャーを募集中！',
      image: 'interview-icon.svg',
      interview: '1回',
      remote: 'true',
      salary: '900,000',
    },
    {
      title:
        '【ReactNative/テスト設計】モバイルアプリ開発におけるバックエンドの案件・求人',
      description: 'バックエンドエンジニアを募集中！',
      image: 'interview-icon.svg',
      interview: '2回',
      remote: 'true',
      salary: '1,000,000',
    },
    {
      title:
        '【React.js/開発仕様策定/要件定義】Web3領域の決済サービス開発における案件・求人',
      description: '顧客サポート担当者を募集中！',
      image: 'interview-icon.svg',
      interview: '1回',
      remote: 'true',
      salary: '700,000',
    },
    {
      title:
        '【TypeScript/React.js】ECサイト開発におけるフロントエンドの案件・求人',
      description: 'データ分析に強いメンバー募集中！',
      image: 'interview-icon.svg',
      interview: '1回',
      remote: 'true',
      salary: '800,000',
    },
    {
      title: '【React.js/AWS】AWSリプレースに向けたエラー解析の案件・求人',
      description: '人材を管理する人事を募集中！',
      image: 'interview-icon.svg',
      interview: '1回',
      remote: 'true',
      salary: '800,000',
    },
    {
      title: '【フロントエンド】スマホアプリ開発の求人・案件',
      description: 'フロントエンドエンジニアを募集中！',
      image: 'interview-icon.svg',
      interview: '1回',
      remote: 'true',
      salary: '700,000',
    },
    {
      title:
        '【TypeScript/一部リモート】製薬会社向け生産管理システム開発の求人・案件',
      description: 'フロントエンドエンジニアを募集中！',
      image: 'interview-icon.svg',
      interview: '1回',
      remote: 'true',
      salary: '800,000',
    },
  ]

  return (
    <Box
      sx={{
        width: ['90%', '80%', '70%', '70%'],
        textAlign: 'center',
        padding: '20px',
        margin: '4px',
      }}
    >
      <h2 sx={{ textAlign: 'center' }}>リクルート募集一覧</h2>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '15px',
        }}
      >
        {cardData.map((card, index) => (
          <Box
            key={index}
            sx={{
              flex: ['1 1 100%', '1 1 48%'],
              backgroundColor: '#f5f8fa',
              borderRadius: '10px',
              padding: '20px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.3s',
              textAlign: 'left',
              minHeight: ['100px', '250px'],
            }}
          >
            <h3
              sx={{
                fontSize: ['16px', '18px'],
                color: '#2980b9',
                marginBottom: '10px',
              }}
            >
              {card.title}
            </h3>
            <p
              sx={{
                fontSize: '12px',
                color: '#7f8c8d',
              }}
            >
              {card.description}
            </p>
            {/* 面接情報の表示 */}
            <Box
              sx={{
                background: '#f2f3f5',
                padding: '5px 10px',
                borderRadius: '5px',
                display: 'inline',
                alignItems: 'flex-start',
                fontSize: '15px',
                marginRight: '10px',
                color: '#666',
                marginTop: '30px',
              }}
            >
              <img
                src={card.image}
                alt="interview icon"
                sx={{ position: 'relative', right: '7px', top: '0px' }}
              />
              <span>面接{card.interview}</span>
            </Box>
            {/* リモート可能の情報表示 */}
            <Box
              sx={{
                background: '#f2f3f5',
                padding: '5px 10px',
                borderRadius: '5px',
                display: 'inline',
                alignItems: 'flex-start',
                fontSize: '15px',
                marginRight: '1px',
                color: '#666',
              }}
            >
              <img
                src="remote-icon.svg"
                alt="remote icon"
                sx={{ position: 'relative', right: '7px', top: '7px' }}
              />
              {card.remote ? 'リモート可能' : 'リモート不可'}
            </Box>
            {/* 給料の表示 */}
            <Box sx={{ marginTop: '15px' }}>
              <img src="yen-icon.svg" alt="yen icon" />
              {card.salary ? (
                <span
                  sx={{
                    fontWeight: '700',
                    color: '#f14b4d',
                    fontSize: '1.5rem',
                    marginLeft: '5px',
                  }}
                >
                  {card.salary.toLocaleString()}
                </span>
              ) : (
                <span
                  sx={{
                    fontWeight: '700',
                    color: '#666',
                    fontSize: '1.5rem',
                    marginLeft: '5px',
                  }}
                >
                  未定
                </span>
              )}
              円／月
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default WorkCard
