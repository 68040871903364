/** @jsx jsx */
import { jsx, Box, Input, Button } from 'theme-ui'
import { useState } from 'react'

export const Filter = () => {
  const [jobType, setJobType] = useState('')

  const [region, setRegion] = useState('')
  const [features, setFeatures] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearch = () => {
    console.log('検索ワード:', searchTerm)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        width: ['85%', '90%', '50%', '30%', '20%'],
        padding: '20px',
        fontSize: ['12px', '15px', '18px'],
        marginTop: ['8px', '64px', '112px'],
        marginLeft: ['0px', '20px'],
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
          fontSize: ['14px', '16px', '20px'],
        }}
      >
        <h3 sx={{ margin: '0px' }}>案件詳細検索</h3>
      </Box>

      <Box sx={{ marginBottom: '10px' }}>
        <label style={{ fontSize: '14px' }}>フリーワード検索</label>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <Input
            sx={{
              flexGrow: 1,
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              fontSize: '15px',
            }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="検索ワード入力"
          />
          <Button
            sx={{
              backgroundColor: '#d3d3d3',
              color: '#000',
              border: 'none',
              borderRadius: '6px',
              marginLeft: '10px',
              padding: '8px',
              height: '100%',
              fontSize: ['12px', '10px', '15px'],
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '4px',
              textWrap: 'nowrap',
              '&:hover': {
                backgroundColor: '#b0b0b0',
              },
            }}
            onClick={handleSearch}
          >
            検索
          </Button>
        </Box>
      </Box>

      {/* 職種フィルター */}
      <Box sx={{ marginBottom: '20px' }}>
        <label style={{ fontSize: '14px' }}>職種</label>
        <Box sx={{ marginTop: '10px' }}>
          <select
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              fontSize: '14px',
            }}
            value={jobType}
            onChange={(e) => setJobType(e.target.value)}
          >
            <option value="">選択してください</option>
            <option value="developer">開発者</option>
            <option value="designer">デザイナー</option>
            <option value="frontendengineer">フロントエンドエンジニア</option>
            <option value="backendengineer">バックエンドエンジニア</option>
            <option value="infrastructureengineer">インフラエンジニア</option>
            <option value="sreengineer">SREエンジニア</option>
            <option value="pm">PM</option>
            <option value="cloud">クラウド</option>
            <option value="mobileengineer">スマホアプリエンジニア</option>
            <option value="gameengineer">ゲームエンジニア</option>
            <option value="pmd">PDM</option>
            <option value="PMO">PMO</option>
            <option value="securityengineer">セキュリティエンジニア</option>
            <option value="serverengineer">サーバエンジニア</option>
            <option value="datascientist">データサイエンティスト</option>
            <option value="designer">デザイナー</option>
          </select>
        </Box>
      </Box>

      {/* 地域フィルター */}

      <Box sx={{ marginBottom: '20px' }}>
        <label style={{ fontSize: '14px' }}>地域</label>
        <Box sx={{ marginTop: '10px' }}>
          <select
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              fontSize: '14px',
            }}
            value={region}
            onChange={(e) => setRegion(e.target.value)}
          >
            <option value="">選択してください</option>
            <option value="tokyo">東京</option>
            <option value="osaka">大阪</option>
            <option value="nagoya">名古屋</option>
          </select>
        </Box>
      </Box>

      <Box sx={{ marginBottom: '50px' }}>
        <Box>
          <p sx={{ margin: '0px', padding: '0px', fontSize: '14px' }}>
            スキルタグ
          </p>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '10px',
              marginTop: '10px',
            }}
          >
            <Box
              sx={{
                display: 'inline-block',
                color: 'rgb(93, 100, 125)',
                fontWeight: 'bold',
                backgroundColor: '#ffffff',
                border: '2px solid rgb(151, 158, 180)',
                borderRadius: '20px',
                fontSize: '12px',
                padding: '8px 16px',
              }}
            >
              Typescript
            </Box>
            <Box
              sx={{
                display: 'inline-block',
                color: 'rgb(93, 100, 125)',
                fontWeight: 'bold',
                backgroundColor: '#ffffff',
                border: '2px solid rgb(151, 158, 180)',
                borderRadius: '20px',

                fontSize: '12px',
                padding: '8px 16px',
              }}
            >
              React
            </Box>
            <Box
              sx={{
                display: 'inline-block',
                color: 'rgb(93, 100, 125)',
                fontWeight: 'bold',
                backgroundColor: '#ffffff',
                border: '2px solid rgb(151, 158, 180)',
                borderRadius: '20px',

                fontSize: '12px',
                padding: '8px 16px',
              }}
            >
              Java
            </Box>
            <Box
              sx={{
                display: 'inline-block',
                color: 'rgb(93, 100, 125)',
                fontWeight: 'bold',
                backgroundColor: '#ffffff',
                border: '2px solid rgb(151, 158, 180)',
                borderRadius: '20px',

                fontSize: '12px',
                padding: '8px 16px',
              }}
            >
              Python
            </Box>
            <Box
              sx={{
                display: 'inline-block',
                color: 'rgb(93, 100, 125)',
                fontWeight: 'bold',
                backgroundColor: '#ffffff',
                border: '2px solid rgb(151, 158, 180)',
                borderRadius: '20px',
                fontSize: '12px',
                padding: '8px 24px',
              }}
            >
              PHP
            </Box>
            <Box
              sx={{
                display: 'inline-block',
                color: 'rgb(93, 100, 125)',
                fontWeight: 'bold',
                backgroundColor: '#ffffff',
                border: '2px solid rgb(151, 158, 180)',
                borderRadius: '20px',

                fontSize: '12px',
                padding: '8px 24px',
              }}
            >
              Vue.js
            </Box>
            <Box
              sx={{
                display: 'inline-block',
                color: 'rgb(93, 100, 125)',
                fontWeight: 'bold',
                backgroundColor: '#ffffff',
                border: '2px solid rgb(151, 158, 180)',
                borderRadius: '20px',
                fontSize: '12px',
                padding: '8px 24px',
              }}
            >
              AWS
            </Box>

            <Box
              sx={{
                display: 'inline-block',
                color: 'rgb(93, 100, 125)',
                fontWeight: 'bold',
                backgroundColor: '#ffffff',
                border: '2px solid rgb(151, 158, 180)',
                borderRadius: '20px',
                fontSize: '12px',
                padding: '8px 24px',
              }}
            >
              ruby
            </Box>
            <Box
              sx={{
                display: 'inline-block',
                color: 'rgb(93, 100, 125)',
                fontWeight: 'bold',
                backgroundColor: '#ffffff',
                border: '2px solid rgb(151, 158, 180)',
                borderRadius: '20px',
                fontSize: '12px',
                padding: '8px 16px',
              }}
            >
              Javascript
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
